/* Responsive Navbar */
@import url('../variable.css');

header {
    position: absolute;
    top: 0;
    left: 2%;
    right: 0;
    width: 90%;
    background-color: transparent;
    z-index: 999;
    margin: 2em;
    font-family: var(--primary-font-family);
}

/* TopHeader */
.TopBar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: var(--hap-gradient-color);
    padding: 15px 3em 15px 15px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    margin: 0 50px;
    color: white;
    /* margin: 2em; */
}
.LeftSection {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    font-size: 15px;
}
.LeftSection div {
    display: flex;
    align-items: center;
}
.RightSection {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
}
.navbar {
    height: 80px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-around;
}

.menubars {
    margin-left: 1rem;
    font-size: 2rem;
    background: none;
    margin-right: 4rem;
}

.navText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-content: flex-end;
    align-items: center;
    padding: 8px 16px 8px 16px;
    list-style: none;
    height: 60px;
}

.navText a {
    text-decoration: none;
    color: black;
    font-size: 18px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-radius: 4px;
}

.navText:hover a {
    color: #f26a82;
}

.navMenuItems {
    width: 100%;
    padding-left: 0px;
    list-style: none;
    margin: 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-y: scroll;
}

span {
    margin-left: 10px;
}

.menubars {
    display: none;
    color: #007bff;
    padding: 8px 7px 0px 8px;
}

.color {
    color: #0abab5;
}
.colorBlack {
    color: black;
}
.droplistitem {
    padding: 7px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.droplistitem:hover {
    list-style-type: disc; /* Set the list style to a filled circle on hover */
    color: #0abab5; /* Set the color to green on hover */
}
.droplistitem:hover a {
    color: #0abab5;
}
.text {
    position: relative;
}
.text .navitem {
    text-decoration: none;
    margin: 0 1rem;
    font-size: 16px;
    color: var(--hap-title-color);
    padding-bottom: 10px;
    font-weight: 500;
    margin-bottom: 1em;
}
.dropdownContainer .navitem {
    padding-bottom: 45px;
}
.text .navitem:hover {
    color: var(--hap-base-color);
}

.dropdownItem {
    display: block;
    padding: 3px 10px;
    text-decoration: none;
    font-size: 15px;
    padding: 15px 15px 5px 15px;
    color: white;
    background-color: inherit;
}

.dropdownItem:hover {
    color: var(--hap-base-color);
    background-color: inherit;
}

.dropdownContainer {
    position: relative;
    display: inline-block;
}

.dropdownContent {
    /* display: none; */
    position: absolute;
    z-index: 1;
    height: 0;
    top: 280%;
    color: whitesmoke;
    background-color: #0d0d0d;
    min-width: 180px;
    line-height: 1.5em;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    font-size: 18px;
    transition: all 0.5s ease;
    overflow: hidden;
}
.navbarScroll .dropdownContainer .dropdownContent {
    top: 230%;
}
.dropdownContainer:hover .dropdownContent,
.dropdownContainer:focus .dropdownContent {
    /* display: block; */
    height: 6em;
    /* margin-top: 10px; */
    transition: all 0.5s ease;
}
#button {
    background: var(--hap-gradient-color);
    color: white;
    border: none;
    padding: 10px 15px;
    border: 2px solid whitesmoke;
    border-radius: 10px;
    text-transform: capitalize;
    transition: 0.5s;
}
.btn {
    flex: 1 1 auto;
    margin: 10px;
    padding: 10px 15px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
}
.btn:hover {
    --x: 100%;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}

/*  */

.dropdownCContent {
    display: none;
    background-color: white;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

/* Style the links inside the dropdown */
.dropdownCContent a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

/* Change color on hover */
.dropdownCContent a:hover {
    background-color: #f1f1f1;
}

/* Show the dropdown content when the corresponding link is clicked */
.Aactive {
    display: initial;
}
/* Big navbar */
.topmenu {
    background-size: contain;
    width: 100%;
    height: 7rem;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 60px;
}

.logo img {
    width: 200px;
}
/* responsive */
.navmenu {
    background-color: var(--title-color);
    width: 50%;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    /* left: -100%; */
    right: -100%;
    transition: 850ms;
    z-index: 999;
    color: white;
}
.navmenu.active {
    /* left: 0; */
    right: 0;
    transition: all 1s ease;
}
.navbarToggle {
    background-color: var(--title-color);
    width: 100%;
    height: 80px;
    display: flex;
    /* justify-content: start; */
    justify-content: flex-start;
    align-items: flex-end;
}
.listitem {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px 20px 20px;
    color: white;
}
.listitem span {
    text-transform: uppercase;
}
.listitem a {
    color: white;
    text-decoration: none;
}
.listitem:hover {
    color: var(--hap-base-color);
}
.listitem:hover a {
    color: var(--hap-base-color);
}
.navMenuItems img {
    height: 80px;
}
.listitemLogo {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 2em;
    color: white;
}
.listitemLogo:hover {
    color: var(--hap-base-color);
}

/* Navbar after scroll */

.navbarScroll {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding: 10px 0px;
}
.navbarScroll .logo img {
    width: 180px;
    object-fit: cover;
}
.navbarScroll .text {
    margin: 0 2rem;
}
.navbarScroll .text .navitem {
    margin: 1.2em;
}
.navbarScroll #btn {
    width: fit-content;
}
.navbarScroll .menubars {
    margin: 0 2rem;
    font-size: 2rem;
    background: none;
    /* margin-right: 4rem; */
}

/* Navbar */
.ScrollBar {
    background-color: white;
    padding: 5px 0px;
    position: fixed;
    top: -10em;
    left: 0;
    transition: all 0.5s ease;
    width: 100%;
}
.ShowOnScroll {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    width: 100%;
    /* display: block; */
}
.ReadyToChat {
    margin-top: 2em;
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
}
.ReadyToChat h2 {
    font-size: 25px;
}
.ReadyToChat button {
    background-color: #007bff;
    border: none;
    border-radius: 30px;
    text-transform: capitalize;
    font-size: 20px;
    padding: 20px 30px;
    width: fit-content;
}
.follow {
    justify-content: center;
    gap: 20%;
    margin: 0.25em 0 1em 0;
}
.NavSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;
    font-size: 30px;
}
.NavSocial a:hover {
    color: #007bff;
}
.container {
    color: black;
    width: 90%;
    margin-top: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1em;
    flex-wrap: wrap;
}
.container h3 {
    position: absolute;
    margin-top: 25px;
    margin-left: 25px;
    font-size: 25px;
}
.item {
    /* background-color: white; */
    color: white;
    width: 30%;
}
.ScrollNavItem1 {
    background-color: white;
    position: relative;
    /* width: 600px; */
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component31.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem2 {
    background-color: white;
    height: 160px;
    position: relative;
    border-radius: 10px;
    background-image: url('../img/Home/Component32.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem3 {
    position: relative;

    background-color: white;
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component33.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem4 {
    position: relative;

    background-color: white;
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component34.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem5 {
    position: relative;

    background-color: white;
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component35.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem6 {
    position: relative;

    background-color: white;
    height: 160px;
    border-radius: 10px;

    background-image: url('../img/Home/Component36.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem7 {
    position: relative;

    background-color: white;
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component37.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem8 {
    position: relative;

    background-color: white;
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component38.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem9 {
    position: relative;
    background-color: white;
    height: 160px;
    border-radius: 10px;
    background-image: url('../img/Home/Component39.webp');
    background-size: cover;
    background-repeat: no-repeat;
}
.ScrollNavItem {
    background-color: #0abab5;
}
.downArrow {
    position: fixed;
    bottom: 12%;
    right: 7%;
    font-size: 25px;
    display: none;
}
.upArrow {
    position: fixed;
    bottom: 12%;
    right: 7%;
    font-size: 25px;
}
/*  */
@media only screen and (max-width: 1235px) {
    .menubars {
        display: initial;
    }
    .text {
        display: none;
    }
    .topmenu {
        background-size: cover;
    }
    .navbar {
        justify-content: space-between;
        padding: 0px 0px 0px 0.5em;
    }
    .navmenu {
        width: 100%;
    }
    .menubars {
        width: 1.5em;
    }
    .menubars img {
        width: 100%;
        object-fit: contain;
    }
}
@media only screen and (max-width: 940px) {
    .TopBar {
        display: none;
    }
    /* header {
    margin: 0em;
  }
  */
    .downArrow {
        display: initial;
    }
    .navbarScroll .menubars {
        font-size: 1.5em;
    }
    .topmenu {
        height: 80px;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
    .menubars {
        font-size: 25px;
    }
    .navbarScroll .logo,
    .navbar .logo {
        width: 150px;
    }
    .navbarScroll .logo img,
    .navabar .logo img {
        width: 100%;
        object-position: center;
    }
    .navmenu {
        width: 100%;
    }
    .item {
        /* background-color: white; */
        color: white;
        width: 40%;
    }
    .ScrollNavItem9 {
        margin-bottom: 150px;
    }
    .navbar {
        padding: 0;
    }
}
@media only screen and (max-width: 840px) {
    .item {
        /* background-color: white; */
        color: white;
        width: 40%;
    }
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 140px;
    }
    .container h3 {
        font-size: 23px;
    }
    /* .navMenuItems .logo {
    width: 80%;
  } */
}
@media only screen and (max-width: 720px) {
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 130px;
    }
    .container h3 {
        font-size: 23px;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 610px) {
    .item {
        width: 60%;
    }
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 160px;
    }
    .ReadyToChat {
        flex-wrap: nowrap;
        /* flex-direction: column; */
        /* width: 50%; */
    }
    .ReadyToChat h2 {
        font-size: 20px;
    }
    .ReadyToChat button {
        background-color: #007bff;
        border: none;
        border-radius: 30px;
        text-transform: capitalize;
        font-size: 18px;
        padding: 15px 25px;
        width: fit-content;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 550px) {
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 130px;
    }
    .container h3 {
        font-size: 20px;
    }
}

@media only screen and (max-width: 530px) {
    .TopBar {
        display: none;
    }
    .topmenu {
        border-radius: 0px;
    }
    header {
        margin: 0em;
        width: 100%;
        left: 0;
    }
    .navbar {
        justify-content: space-between;
    }
    .menubars {
        margin-right: 1em;
    }
    .navmenu {
        width: 90%;
    }
    .navbarScroll .logo {
        width: 150px;
    }
    .navbarScroll .menubars {
        font-size: 1.5em;
    }
    .navmenu {
        width: 100%;
    }
    .navMenuItems .logo {
        height: 80px;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 480px) {
    .item {
        width: 70%;
    }
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 130px;
    }
    .container h3 {
        font-size: 20px;
    }
    .navMenuItems .logo {
        height: 70px;
    }
    /* .ReadyToChat {
    margin-top: 1em;
  } */

    .ReadyToChat h2 {
        font-size: 18px;
    }
    .ReadyToChat button {
        background-color: #007bff;
        border: none;
        border-radius: 30px;
        text-transform: capitalize;
        font-size: 16px;
        padding: 10px 15px;
        width: fit-content;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 400px) {
    .item {
        width: 70%;
    }
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 100px;
    }
    .ScrollNavItem6 {
        height: 90px;
    }
    .container h3 {
        font-size: 20px;
    }

    .ReadyToChat h2 {
        font-size: 18px;
    }
    .ReadyToChat button {
        background-color: #007bff;
        border: none;
        border-radius: 30px;
        text-transform: capitalize;
        font-size: 16px;
        padding: 10px 15px;
        width: fit-content;
    }
    .ReadyToChat {
        /* margin-top: 0.25em; */
        /* flex-direction: column; */
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 387px) {
    .ReadyToChat h2 {
        font-size: 15px;
    }
    .ReadyToChat button {
        background-color: #007bff;
        border: none;
        border-radius: 30px;
        text-transform: capitalize;
        font-size: 15px;
        padding: 10px 15px;
        width: fit-content;
    }
    .ReadyToChat {
        margin-top: 0;
        /* flex-direction: column; */
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 358px) {
    .ReadyToChat h2 {
        font-size: 15px;
    }
    .ReadyToChat button {
        background-color: #007bff;
        border: none;
        border-radius: 30px;
        text-transform: capitalize;
        font-size: 15px;
        padding: 10px 15px;
        width: fit-content;
    }
    .ReadyToChat {
        margin-top: 0;
        flex-direction: column;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 300px) {
    .menubars {
        font-size: 25px;
    }
    .logo img {
        width: 80%;
    }
    .navmenu {
        width: 100%;
    }
    .item {
        width: 70%;
    }
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 90px;
    }

    .container h3 {
        font-size: 18px;
    }
    .navbar .logo img {
        /* width: 80%; */
        height: fit-content;
    }
    .navbar .navbaranchor {
        font-size: 20px;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
@media only screen and (max-width: 300px) {
    .item {
        width: 70%;
    }
    .ScrollNavItem1,
    .ScrollNavItem2,
    .ScrollNavItem3,
    .ScrollNavItem4,
    .ScrollNavItem5,
    .ScrollNavItem6,
    .ScrollNavItem7,
    .ScrollNavItem8,
    .ScrollNavItem9 {
        height: 80px;
    }
    .container h3 {
        font-size: 15px;
    }
    .navbar .logo img {
        width: 100%;
        height: 50px;
    }
}
.navitemactive {
    color: #007bff !important;
}
.navitemactive a {
    color: #007bff !important;
}
