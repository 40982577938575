.HeroSection {
    /* height: 50vh; */
    font-family: var(--primary-font-family);
    background-color: rgb(246, 244, 247);
    display: flex;
    gap: 0.5%;
    justify-content: center;
    align-items: center;
    padding: 4em 0 5em 0;
}
.LeftSection {
    width: 43%;
}

.LeftSection p {
    text-transform: capitalize;
    margin-bottom: 3em;
}
.RightSection {
    width: 40%;
    margin-bottom: 2em;
    overflow: hidden;
    background-image: url('../../img/Background/Component\ 22.webp');
    padding: 3em 2.5em 2em 3em;
    background-repeat: no-repeat;
    background-position: top left;
}
.Video {
    width: 100%;
    border-radius: 10px;
}
.CardSection {
    background-image: url('../../img/Background/Component\ 21.webp');
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 6em 0px;
    font-family: var(--primary-font-family);
}
.Card {
    width: 25%;
    height: 277px;
    background-color: var(--footer-bg);
    color: white;
    border-radius: 20px;
    text-align: center;
    padding: 20px 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    /*  */

    background: rgba(137, 194, 255, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4.8px);
    -webkit-backdrop-filter: blur(4.8px);
    border: 1px solid rgba(137, 194, 255, 0.11);
    /*  */
}
.Card:hover {
    box-shadow: 5px 10px 8px #88888837;
    box-shadow: 5px 10px 8px #eca8ff28;

    animation: floatAnimation 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
@keyframes floatAnimation {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(
            -10px
        ); /* Adjust the horizontal float distance as needed */
    }
}
.rectangle,
.rectangleTop {
    width: 450px;
    height: 600px;
    position: absolute;
    background-color: #007bff55;
    transform: rotate(-55deg);
    transition: all 1s ease;
}
.rectangle {
    left: -110%;
    bottom: -110%;
}
.rectangleTop {
    right: -110%;
    top: -110%;
}
/* .Card:hover .rectangle,
.CardR:hover .rectangle {
  top: 20%;
  left: -40%;
} */
/* .Card:hover .rectangleTop,
.CardR:hover .rectangle {
  top: 20%;
  left: -40%;
} */
.Card img {
    width: 25%;
}
.Card p {
    line-height: 25px;
}
.Form {
    font-family: var(--primary-font-family);
    padding: 5em 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.Form h4,
.FAQSection h4,
.HeroSection h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
}
.Form h1,
.LeftSection h1,
.FAQSection h1 {
    text-transform: uppercase;
    font-size: 65px;
    margin: 0;
}
.Form form {
    margin-top: 5em;
    width: 70%;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}
.column {
    /* border: 1px solid #ccc; */
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.column:nth-child(8) {
    display: flex;
    align-items: center;
    justify-content: center;
}
.column input,
.column select,
.column textarea {
    padding: 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.39);
    font-size: 15px;
}
.span2 {
    grid-column: span 2;
}
.gridContainerSelect {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.btn {
    flex: 1 1 auto;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 40%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    border: none;
    width: fit-content;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}

/* FAQ */
.FAQSection {
    background-image: url('../../img/Background/Component\ 20.webp');
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 6em 0px;
    font-family: var(--primary-font-family);
}
.glass {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    padding: 3em 0 6em 0;
    background: rgba(137, 194, 255, 0.09);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.8px);
    -webkit-backdrop-filter: blur(4.8px);
    border: 1px solid rgba(137, 194, 255, 0.11);
}
.FAQSection h1 {
    color: white;
    font-size: 65px;
    margin: 0;
    padding: 0;
}
.accordian {
    margin-top: 4em;
    width: 80%;
}
.Questions {
    font-family: var(--primary-font-family);
    padding: 3em;
    display: flex;
    justify-content: center;
    gap: 3em;
    background-image: url('../../img/Background/test-bg2.webp');
}
.Questions div:nth-child(1) {
    width: 50%;
}
.Questions div:nth-child(2) {
    width: 20%;
    display: flex;
    align-items: center;
}
.Questions .btn,
.LeftSection .btn {
    color: #007bff;
    width: fit-content;
}
a {
    text-decoration: none;
    color: white;
}

@media only screen and (max-width: 1200px) {
    .LeftSection h1,
    .Form h1,
    .FAQSection h1 {
        font-size: 55px;
    }
    .HeroSection h4,
    .Form h4,
    .FAQSection h1 {
        font-size: 15px;
    }
    .HeroSection {
        flex-wrap: wrap;
        gap: 2em;
    }
    .LeftSection {
        width: 60%;
    }
    .RightSection {
        width: 50%;
    }
    .RightSection video {
        width: 90%;
    }
    .LeftSection {
        text-align: center;
    }
    .CardSection {
        flex-wrap: wrap;
    }
    .Card {
        width: 40%;
    }
}
@media only screen and (max-width: 1000px) {
    .LeftSection h1,
    .Form h1,
    .FAQSection h1 {
        font-size: 45px;
    }
    .HeroSection h4,
    .Form h4,
    .FAQSection h4 {
        font-size: 15px;
    }
    .RightSection {
        width: 60%;
        background-image: none;
    }
    .RightSection video {
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .RightSection {
        width: 70%;
        background-image: none;
    }
    .RightSection video {
        width: 100%;
    }
    .Questions {
        flex-wrap: wrap;
    }
    .Questions div:nth-child(1) {
        width: 100%;
        text-align: center;
    }
    .Questions div:nth-child(2) {
        width: fit-content;
    }
}

@media only screen and (max-width: 850px) {
    .HeroSection h1,
    .Form h1,
    .FAQSection h1 {
        font-size: 45px;
    }
    .HeroSection h4,
    .Form h4,
    .FAQSection h4 {
        font-size: 15px;
    }
    .column {
        grid-column: span 2;
    }
    .Card {
        width: 60%;
    }
}
@media only screen and (max-width: 790px) {
    .gridContainerSelect div {
        grid-column: span 2;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .glass {
        width: 85%;
    }
}
@media only screen and (max-width: 600px) {
    .HeroSection {
        padding: 2em 0 1em 0;
    }
    .HeroSection h1,
    .Form h1,
    .FAQSection h1 {
        font-size: 40px;
    }
    .HeroSection h4,
    .Form h4,
    .FAQSection h4 {
        font-size: 13px;
    }
    .btn {
        font-size: 13px;
        padding: 15px 15px;
    }
    .RightSection {
        width: 80%;
        background-image: none;
    }
    .RightSection video {
        width: 100%;
    }
    .Card h2 {
        font-size: 17px;
    }
    .Card p {
        font-size: 14px;
    }
    .glass {
        padding: 3em 0 3em 0;
    }
}
@media only screen and (max-width: 450px) {
    .HeroSection h1,
    .Form h1,
    .FAQSection h1 {
        font-size: 38px;
        text-align: center;
    }
    .HeroSection h4,
    .Form h4,
    .FAQSection h4 {
        font-size: 15px;
    }
    .Form .column {
        font-size: 15px;
    }
    .column input,
    .column textarea {
        width: 90%;
    }
    .column input[type='checkbox'] {
        width: auto;
    }
    .Card {
        width: 60%;
    }
    .rectangle {
        left: -160%;
        bottom: -110%;
    }
    .rectangleTop {
        right: -160%;
        top: -110%;
    }
    .glass {
        width: 85%;
    }
}
@media only screen and (max-width: 281px) {
    .HeroSection h1,
    .Form h1,
    .FAQSection h1 {
        font-size: 25px;
        text-align: center;
    }
    .HeroSection h4,
    .Form h4,
    .FAQSection h4 {
        font-size: 13px;
        text-align: center;
    }
    .column input,
    .column textarea {
        width: 80%;
    }
    .column select {
        width: 95%;
    }
}
