.outerDiv {
    color: Black;
    background-color: white;
    background-image: url('../../img/Background/appbg1.webp');
    display: flex;
    height: 100vh;
    justify-content: space-around;
    padding: 4em 0 4.5em 0;
    font-family: var(--primary-font-family);
    position: relative;
    overflow: hidden;
}
.outerDiv > img {
    position: absolute;
    width: 100%;
}
.RightSection {
    width: 40%;
    display: flex;
    align-items: center;
}
.RightSection img {
    width: 100%;
    object-fit: contain;
    /* height: 600px; */
    z-index: 2;
}
.RightSection .ButtonDivs {
    display: none;
}
.LeftSection {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
}
.LeftSection h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.LeftSection h1 {
    font-size: 65px;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
}
.LeftSection p {
    text-align: justify;
}
.ButtonDivs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
}
.Button {
    width: 200px;
    background: rgb(0, 0, 0);
    background: linear-gradient(
        97deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(33, 33, 33, 1) 43%,
        rgba(42, 42, 42, 1) 66%,
        rgba(83, 83, 83, 1) 94%,
        rgba(83, 83, 83, 1) 94%
    );
    color: white;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    border: 1px solid white;
    border-radius: 10px;
    padding: 0 0 0 10px;
}
.Button img {
    position: relative;
    width: 40px;
    object-fit: contain;
}
.Button div {
    padding: 10px 10px 10px 10px;
}
.Button div h5 {
    margin: 0;
    text-transform: uppercase;
    font-weight: 200;
}
.Button div p {
    margin: 0;
    font-size: 28px;
    font-family: 'Times New Roman', Times, serif;
}
@media only screen and (max-width: 1300px) {
    .outerDiv {
        height: 90vh;
    }
}
@media only screen and (max-width: 1050px) {
    .outerDiv {
        height: 80vh;
    }
    .LeftSection h4 {
        font-size: 14px;
    }
    .LeftSection h1 {
        font-size: 55px;
    }
}
@media only screen and (max-width: 890px) {
    .outerDiv {
        /* height: 80vh; */
        height: fit-content;
    }
    .LeftSection h4 {
        font-size: 13px;
    }
    .LeftSection h1 {
        font-size: 50px;
    }
    .LeftSection p {
        font-size: 13px;
    }
    .Button {
        width: 180px;
        padding: 0 0 0 10px;
    }
    .Button img {
        width: 30px;
    }
    .Button div {
        padding: 7px 7px 7px 7px;
    }
    .Button div h5 {
        font-weight: 200;
        font-size: 10px;
    }
    .Button div p {
        font-size: 25px;
    }
}
@media only screen and (max-width: 800px) {
    .LeftSection h1 {
        font-size: 45px;
    }
    .LeftSection p {
        font-size: 13px;
    }
    .ButtonDivs {
        gap: 10px;
    }
    .Button {
        width: 160px;
        padding: 0 0 0 7px;
    }
    .Button img {
        width: 30px;
    }
    .Button div {
        padding: 6px 6px 6px 6px;
    }
    .Button div p {
        font-size: 23px;
    }
}
@media only screen and (max-width: 730px) {
    .outerDiv {
        flex-direction: column-reverse;
        align-items: center;
        background-image: none;

        justify-content: flex-start;
        /* padding: 1em 0 4.5em 0; */
        /* height: 100vh; */
    }
    .RightSection {
        width: 50%;
        display: flex;
        flex-direction: column;
        z-index: 2;
        gap: 1em;
    }
    .LeftSection {
        width: 80%;
    }
    .LeftSection h1 {
        font-size: 45px;
    }
    .LeftSection p {
        font-size: 13px;
    }
    .LeftSection .ButtonDivs {
        display: none;
    }
    .RightSection .ButtonDivs {
        display: initial;
        display: flex;
    }
    .Button {
        width: 150px;
        padding: 0 0 0 10px;
    }
    .Button img {
        width: 25px;
    }
    .Button div {
        padding: 7px 7px 7px 7px;
    }
    .Button div h5 {
        font-weight: 200;
        font-size: 10px;
    }
    .Button div p {
        font-size: 20px;
    }
}
@media only screen and (max-width: 520px) {
    .LeftSection h1 {
        font-size: 40px;
    }
    .Button div p {
        font-size: 18px;
    }
}
@media only screen and (max-width: 400px) {
    .RightSection {
        width: 70%;
        gap: 1em;
    }
}
@media only screen and (max-width: 370px) {
    .ButtonDivs {
        flex-direction: column;
    }
}
@media only screen and (max-width: 280px) {
    .LeftSection h1 {
        font-size: 35px;
    }
}
