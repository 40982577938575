.Footer {
    background-color: var(--footer-bg);
    color: white;
    display: flex;
    flex-direction: column;
    font-family: var(--primary-font-family);
    padding-top: 2em;
}
.Footer h2 {
    text-decoration: underline;
    text-transform: uppercase;
}
.container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 2em 0em;
}
.IntroSection {
    width: 25%;
}
.IntroSection img {
    margin-top: 1.5em;
    width: 50%;
}
.IntroSection p {
    text-align: justify;
    color: #7f7f7f;
}
.pictures {
    display: flex;
    gap: 13px;
    justify-content: flex-start;
    align-items: center;
    margin-top: -30px;
    margin-left: -18px;
}
.pictures img {
    width: 35%;
}
.LeftSection {
    width: 20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.LeftSection div {
    padding: 5px;
    border-bottom: 1px solid rgba(128, 128, 128, 0.135);
}
.LeftSection div a {
    text-decoration: none;
    color: white;
}
.LeftSection div a:hover {
    color: var(--hap-base-color);
}
.RightSection {
    width: 25%;
    display: flex;
    flex-direction: column;
}
.RightSection img {
    width: 90%;
}
.contactDetails {
    display: flex;
    align-items: center;
}

.contactDetails a {
    text-decoration: none;
    color: inherit;
}

.contactDetails svg {
    margin-right: 10px;
}
.contactDetails a:hover {
    color: var(--hap-base-color);
}
.contactDetails p {
    word-break: break-all;
}
.copyright {
    background-color: #151515;
    padding: 25px;
    text-align: center;
}
.Services {
    border-radius: 30px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: hidden;
    flex-direction: column;
}

@media only screen and (max-width: 1100px) {
    .IntroSection {
        width: 40%;
    }
    .RightSection {
        width: 90%;
        gap: 3em;
        flex-direction: row;
    }
    .LeftSection {
        width: 40%;
    }
    .container {
        flex-wrap: wrap;
        gap: 20px;
    }
}
@media only screen and (max-width: 600px) {
    .IntroSection {
        width: 80%;
    }
    .RightSection {
        width: 80%;
        flex-direction: column;
    }
    .LeftSection {
        width: 80%;
    }
    .container {
        gap: 20px;
    }
}

@media only screen and (max-width: 500px) {
    .pictures {
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-top: 0;
        margin-left: 0;
    }

    .pictures img {
        width: 25%;
    }
}

@media only screen and (max-width: 300px) {
    h2 {
        font-size: 20px;
    }
}
