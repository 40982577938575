.ContactDiv {
    font-family: var(--primary-font-family);
}

.FirstDiv {
    margin-top: 0;
    background-image: url('../../img/Background/test-bg2.webp');
    padding-top: 5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 5em;
}
.textDiv {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-bottom: 5%;
}
.FirstDiv h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
}
.FirstDiv .textDiv h3 {
    font-size: 40px;
    margin: 10px 0;
    font-weight: 900;
    letter-spacing: 2px;
}
.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 80%;
}
.Form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 48%;
}
.Form input,
.Form textarea {
    padding: 20px;
    border: 1px solid rgba(128, 128, 128, 0.205);
    outline: none;
    background-color: rgb(246, 244, 247);
    font-size: 15px;
    font-family: var(--primary-font-family);
}
.Info {
    width: 46%;
    border-left: 1px solid black;
    padding: 20px 0 30px 30px;
}
.gridItem {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 15px 5px;
}
.text h6 {
    font-size: 18px;
    text-transform: uppercase;
    margin: 10px 0;
}
.text a,
.text p {
    color: grey;
    margin: 5px 0;
    text-decoration: none;
    display: block;
}
.text .call {
    display: inline;
}
.text a:hover {
    color: var(--hap-base-color);
}
.btn {
    flex: 1 1 auto;
    margin-bottom: 10px;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    border-radius: 20px;
    border: none;
    width: 30%;
    text-transform: uppercase;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
.text a {
    word-break: break-all;
}
/* Block Section */
.ContactInfo {
    margin-top: 3em;
    display: flex;
    justify-content: center;
    gap: 35px;
    background-color: #1e1e1e;
}
.InnerContactInfo {
    width: 25%;
    border: 1px solid white;
    padding: 1em 2em 2em 2em;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}
.InnerContactInfo h1 {
    text-align: left;
    color: white;
}
.InnerContactInfo p {
    text-align: left;
    font-size: 18px;
    margin: 0;
    color: white;
    font-family: 'Montserrat', sans-serif;
}
.Icon {
    padding: 8px 14px;
    background-color: var(--hap-base-color);
    color: white;
    font-size: 20px;
    transition: all 1s ease;
}
.InnerContactInfo:hover .Icon {
    background-color: black;
    color: white;
}
@media only screen and (max-width: 850px) {
    .container {
        flex-wrap: wrap;
    }
    .Form {
        width: 100%;
    }
    .Info {
        width: 100%;
        border: none;
        padding: 0;
    }
    .Info .text h6,
    .Info .text p,
    .Info .text a {
        font-size: 15px;
    }
    .Form input,
    .Form textarea {
        padding: 15px;
        font-size: 13px;
    }
}
@media only screen and (max-width: 600px) {
    .container {
        flex-wrap: wrap;
    }
    .Form {
        width: 100%;
    }
    .Info {
        width: 100%;
        padding: 0;
    }
    .ContactDiv .textDiv h3 {
        font-size: 40px;
    }
    .textDiv p,
    .textDiv h4 {
        font-size: 15px;
    }
    .Info h4 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 500px) {
    .ContactDiv .textDiv h3 {
        font-size: 30px;
    }
    .Info {
        padding: 0;
    }
    .textDiv p,
    .textDiv h4 {
        font-size: 15px;
    }
    .Info .text h6,
    .Info .text p,
    .Info .text a {
        font-size: 13px;
    }
    .btn {
        width: fit-content;
        font-size: 15px;
    }
}
