.outerDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--footer-bg);
  font-family: var(--primary-font-family);
}
/* Design */
.DesignDiv {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10em;
  overflow-x: hidden;
}
.DesignDiv hr {
  width: 90%;
  background: var(--hap-gradient-color-reverse);
  height: 2px;
  border: none;
}
.heading {
  width: 80%;
  text-align: center;
  margin: 3em 3em 6em 3em;
}
.heading h1 {
  font-size: 65px;
  margin: 0;
}
.rows {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
  margin: 3em 0 3em 0;
}

.rows img {
  width: 100%;
  object-fit: contain;
}
.rows .arrow img {
  height: 50px;
}
.icons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 5%;
}
.text {
  width: 50%;
  transition: all 2s ease;
}
.rows:hover .text {
  background: var(--hap-gradient-color-reverse);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text h2 {
  font-size: 60px;
  margin: 0;
}
.text h5 {
  margin: 0;
  font-size: 20px;
  font-weight: 200;
}
.Buttons {
  width: 25%;
  display: flex;
}

.btn {
  flex: 1 1 auto;
  margin: 10px;
  font-size: 15px;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: background 0.5s ease; /* Changed the transition property */
  background-color: #1e1e1e; /* Initial grey background color */
  border: 1px solid white;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  position: relative;
  overflow: hidden; /* Hide overflow to prevent gradient spillover */
  color: white;
  z-index: 1;
}
.Star {
  margin: 4px 2px 0px 2px;
}
.btn::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 100%; /* Pseudo-element starts from the left edge of the button */
  background: linear-gradient(
    90deg,
    var(--c1, #1e1e1e),
    var(--c2, #1e1e1e) 100%,
    var(--c1, #1e1e1e)
  );
  transition: right 0.5s ease; /* Animation duration and easing */
  border-radius: inherit;
  z-index: -1;
}
.btn:hover {
  border: 1px solid #007bff;
}
.btn:hover::after {
  right: 0; /* Move the pseudo-element to the right on hover */
}
#btn1 {
  --c1: #c600ff;
  --c2: #007bff;
  --c3: #1e1e1e;
  color: white;
}
.rows:hover .arrow {
  transform: rotate(0deg);
}

.arrow {
  width: 10%;
  transform: rotate(-30deg);
  transition: transform 0.5s ease;
}
/* Marquee */
.MarqueeSection {
  margin-bottom: -8em;
  background-color: black;
  width: 90%;
  padding: 50px 0px 60px 0px;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  overflow: hidden;
}
.DesignDiv h4,
.MarqueeSection h4 {
  background: var(--hap-gradient-color-reverse);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.MarqueeSection h1 {
  color: white;
  margin: 10px 0;
  font-family: var(--primary-font-family);
  font-size: 70px;
}
.First,
.Third,
.Fourth,
.Second {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Second img {
  margin: 0 25px;
}
/* .First span {
  width: 30%;
} */
.First .FirstMarquee {
  width: 30%;
}
.FirstMarquee,
.LastMarquee {
  display: inline;
  background: var(--hap-gradient-color);
  border-radius: 50px;
  font-size: 20px;
  padding: 18px;
}
.LastMarquee {
  width: 725px;
}
.Third .FirstMarquee {
  width: 30%;
}
.FourthTexts {
  color: white;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.Fourth {
  width: 87%;
  gap: 10px;
}
.FourthText {
  width: 62%;
  margin: 0;
  color: white;
  -webkit-text-fill-color: black; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.LastMarquee {
  width: 50%;
}
@media only screen and (max-width: 1300px) {
  .Buttons {
    display: none;
  }
}
@media only screen and (max-width: 1250px) {
  .MarqueeSection h1 {
    font-size: 60px;
  }
}
@media only screen and (max-width: 1100px) {
  .MarqueeSection h1 {
    font-size: 55px;
  }
  .FirstMarquee,
  .LastMarquee {
    padding: 13px;
  }
  .Second img {
    height: 60px;
  }
}
@media only screen and (max-width: 1000px) {
  .heading h1 {
    font-size: 55px;
  }
  .text h2 {
    font-size: 50px;
  }
  .text h5 {
    font-size: 18px;
  }
  .arrow img {
    width: 100px;
  }
  .icons {
    width: 6%;
  }
  .MarqueeSection h1 {
    font-size: 50px;
  }
  .FirstMarquee,
  .LastMarquee {
    padding: 10px;
    font-size: 17px;
  }
  .Second img {
    height: 60px;
  }
}
@media only screen and (max-width: 940px) {
  .MarqueeSection h1 {
    font-size: 45px;
  }
  .FirstMarquee,
  .LastMarquee {
    padding: 7px;
    font-size: 15px;
  }
  .Second img {
    height: 50px;
  }
}
@media only screen and (max-width: 870px) {
  .heading h1 {
    font-size: 55px;
  }
  .text h2 {
    font-size: 50px;
  }
  .text h5 {
    font-size: 18px;
  }
  .arrow img {
    width: 100px;
  }
  .icons {
    width: 6%;
  }
  .MarqueeSection h1 {
    font-size: 40px;
  }
  .FirstMarquee,
  .LastMarquee {
    padding: 7px;
    font-size: 15px;
  }
  .Second img {
    height: 50px;
  }
}
@media only screen and (max-width: 800px) {
  .heading h1 {
    font-size: 45px;
  }
  .text h2 {
    font-size: 40px;
  }
  .text h5 {
    font-size: 13px;
  }
  .rows {
    justify-content: space-around;
  }
  .rows .arrow img {
    height: 40px;
  }
  .icons {
    width: 6%;
  }
}
@media only screen and (max-width: 750px) {
  .outerDiv {
    overflow: hidden;
  }
  .MarqueeSection {
    display: none;
  }
  .DesignDiv {
    margin-bottom: 4em;
  }
}
@media only screen and (max-width: 580px) {
  .heading {
    margin: 3em 3em 3em 3em;
  }
  .text h2 {
    font-size: 30px;
  }
  .text h5 {
    font-size: 10px;
  }
  .rows .arrow {
    margin-right: 20px;
  }
  .rows .arrow img {
    height: 35px;
  }
  .icons {
    width: 7%;
  }
}
@media only screen and (max-width: 450px) {
  .heading h1 {
    font-size: 40px;
  }
  .text h2 {
    font-size: 33px;
  }
  .text h5 {
    font-size: 10px;
  }
  .rows .arrow img {
    height: 35px;
  }
  .icons {
    width: 7%;
  }
}
@media only screen and (max-width: 450px) {
  .heading h1 {
    font-size: 35px;
  }
  .heading h4 {
    font-size: 13px;
  }
  .text h2 {
    font-size: 26px;
  }
  .text h5 {
    font-size: 10px;
  }
  .rows {
    margin: 2em 0 2em 0;
  }
  .rows .arrow img {
    height: 30px;
  }
  .icons {
    width: 7%;
  }
}
@media only screen and (max-width: 400px) {
  .heading h1 {
    font-size: 35px;
  }
  .heading h4 {
    font-size: 13px;
  }
  .text h2 {
    font-size: 26px;
  }
  .text h5 {
    font-size: 10px;
  }

  .rows .arrow {
    display: none;
  }
  .icons {
    width: 10%;
  }
}
@media only screen and (max-width: 290px) {
  .heading h1 {
    font-size: 30px;
  }
  .text h2 {
    font-size: 23px;
  }
}
