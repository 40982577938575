.outersection {
    background-image: url(../../img/Background/test-bg21.webp);
    font-family: var(--primary-font-family);
    padding: 4em 0;
    font-family: var(--primary-font-family);
}
.outersection .HeadingHEAD {
    text-align: center;
}
.Steps {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-wrap: wrap;
    gap: 1.5em;
    padding: 3em 0 2em 0;
    position: relative;
}
.outersection h4,
.consultaion h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 1% 0 2% 0;
}
.outersection h1 {
    font-size: 65px;
    margin: 0;
    text-transform: uppercase;
}
.StepOne,
.StepTwo,
.StepThree,
.StepFour,
.StepFive {
    width: 65%;
    font-family: 'Roboto', sans-serif;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7em;
    /* flex-direction: column; */
}
.glass div div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
}
.glass div div p {
    margin: 0;
}
.StepTwo,
.StepFour {
    gap: 2em;
}
.Background {
    font-size: 176px;
    letter-spacing: 0.02em;
    font-weight: 600;
    position: relative;
    margin: 0;
    color: rgb(255, 234, 232);
}

.Heading {
    position: absolute;
    top: 40%;
    left: 55%;
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    text-transform: uppercase;
    color: black;
}
.StepTwo .Background {
    color: rgb(236, 219, 255);
    margin-right: 30px;
}
.StepThree .Background {
    color: rgb(212, 237, 238);
}
.StepFour .Background {
    color: rgb(212, 218, 238);
    margin-right: 75px;
}
.StepFive .Background {
    color: rgb(238, 212, 212);
}
.Heading p {
    margin: 0;
}
.glass p {
    margin: 0;
    font-weight: 500;
    font-size: 16px;
}
.glass {
    padding: 3% 3%;
    width: 80%;
    background: rgba(0, 123, 255, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(0.5px);
    -webkit-backdrop-filter: blur(3.5px);
    border: 1px solid rgba(0, 123, 255, 0.23);
    display: flex;
    font-size: 30px;
    justify-content: space-between;
}
.consultaion {
    background-color: var(--footer-bg);
    padding: 3% 0;
    font-family: var(--primary-font-family);
    text-align: center;
    padding: 3em 0 6em 0;
    /* margin: 1em 0; */
}
.consultaion h1 {
    color: white;
    text-align: center;
    margin: 0;
    font-size: 65px;
    text-transform: uppercase;
}
.consultationitems {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: white;
    margin: 3em 0;
    gap: 30px;
}
.consultationitems .items {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.consultationitems .items img {
    width: 50%;
}
.items h2 {
    margin: 20px 0 0 0;
}
.btn {
    flex: 1 1 auto;
    margin-bottom: 10px;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    text-decoration: none;
    font-family: var(--primary-font-family);
    letter-spacing: 1px;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 51%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    border: none;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
.icons1 {
    position: absolute;
    left: 3%;
    top: 7%;
    width: 130px;
    animation: floatAnimation 2s ease-in-out infinite;
}
.icons2 {
    position: absolute;
    right: 3%;
    top: 26%;
    width: 130px;
    animation: floatAnimation 3s ease-in-out infinite;
}
.icons3 {
    position: absolute;
    left: 3%;
    top: 46%;
    width: 110px;
    animation: floatAnimation 2.5s ease-in-out infinite;
}
.icons4 {
    position: absolute;
    right: 3%;
    top: 66%;
    width: 150px;
    animation: floatAnimation 3.5s ease-in-out infinite;
}
.icons5 {
    position: absolute;
    left: 3%;
    bottom: 7%;
    width: 150px;
    animation: floatAnimation 4s ease-in-out infinite;
}
@keyframes floatAnimation {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(
            50px
        ); /* Adjust the horizontal float distance as needed */
    }
}
.icons1 img,
.icons2 img,
.icons3 img,
.icons4 img,
.icons5 img {
    width: 100%;
}
@media only screen and (max-width: 1260px) {
    .glass p {
        font-size: 15px;
    }
    .items h2 {
        font-size: 20px;
    }
    .items p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 1200px) {
    .outersection h1,
    .consultaion h1 {
        font-size: 55px;
    }
    .outersection h4,
    .consultaion h4 {
        font-size: 15px;
    }
    .glass p {
        font-size: 15px;
    }
    .icons1,
    .icons2,
    .icons3,
    .icons4,
    .icons5 {
        display: none;
    }
    .StepOne,
    .StepTwo,
    .StepThree,
    .StepFour,
    .StepFive {
        width: 80%;
    }
    .items h2 {
        font-size: 18px;
    }
    .items p {
        font-size: 13px;
    }
}
@media only screen and (max-width: 1000px) {
    .outersection h1,
    .consultaion h1 {
        font-size: 45px;
    }
    .outersection h4,
    .consultaion h4 {
        font-size: 15px;
    }
    .glass p {
        font-size: 12px;
    }
    .StepOne,
    .StepTwo,
    .StepThree,
    .StepFour,
    .StepFive {
        width: 85%;
    }
}
@media only screen and (max-width: 850px) {
    .outersection h1,
    .consultaion h1 {
        font-size: 45px;
    }
    .outersection h4,
    .consultaion h4 {
        font-size: 15px;
    }
    .column {
        grid-column: span 2;
    }
}
@media only screen and (max-width: 800px) {
    .StepOne,
    .StepThree,
    .StepFive {
        flex-direction: column;
        gap: 0;
    }
    .StepTwo,
    .StepFour {
        flex-direction: column-reverse;
        gap: 0;
    }
    .StepFour .Background,
    .StepTwo .Background {
        margin-right: 0;
    }
    .icons1,
    .icons2,
    .icons3,
    .icons4,
    .icons5 {
        display: initial;
    }
    .icons1 {
        top: 3%;
    }
    .icons5 {
        bottom: 13%;
    }
    .consultationitems .items {
        width: 40%;
    }
    .consultationitems {
        flex-wrap: wrap;
    }
}
@media only screen and (max-width: 600px) {
    .outersection h1,
    .consultaion h1 {
        font-size: 40px;
    }
    .outersection h4,
    .consultaion h4 {
        font-size: 13px;
    }
    .btn {
        font-size: 13px;
        padding: 15px 15px;
    }
    .icons1,
    .icons2,
    .icons3,
    .icons4,
    .icons5 {
        display: none;
    }
}
@media only screen and (max-width: 500px) {
    .Background {
        font-size: 150px;
    }
    .Background .Heading p {
        font-size: 15px;
    }
    .glass p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 450px) {
    .outersection h1,
    .consultaion h1 {
        font-size: 35px;
    }
    .outersection h4,
    .consultaion h4 {
        font-size: 15px;
    }
    .Form .column {
        font-size: 15px;
    }
    .column input,
    .column textarea {
        width: 90%;
    }
    .glass {
        flex-direction: column;
    }
    .items h2 {
        font-size: 15px;
    }
    .items p {
        font-size: 10px;
    }
    .consultationitems .items {
        width: 70%;
    }
    .consultationitems .items h2 {
        font-size: 20px;
    }
    .consultationitems .items p {
        font-size: 15px;
    }
}
@media only screen and (max-width: 340px) {
    .Background {
        font-size: 130px;
    }
    .Background .Heading p {
        font-size: 13px;
    }
}
@media only screen and (max-width: 281px) {
    .outersection h1,
    .consultaion h1 {
        font-size: 23px;
    }
    .outersection h4,
    .consultaion h4 {
        font-size: 13px;
    }
    .column input,
    .column textarea {
        width: 80%;
    }
    .Background {
        font-size: 150px;
    }
    .Background .Heading p {
        font-size: 12px;
    }
    .column select {
        width: 95%;
    }
}
