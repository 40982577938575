.careerdiv {
    font-family: var(--primary-font-family);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4em;
    background-image: url('../../img/Background/test-bg.webp');
}
.careerdiv h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
}
.careerdiv h1 {
    text-transform: uppercase;
    font-size: 65px;
    margin: 0;
}
.Form {
    background: rgba(255, 255, 255, 0.08);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(7.2px);
    -webkit-backdrop-filter: blur(7.2px);
    border: 1px solid rgba(255, 255, 255, 0.13);
    width: 90%;
    margin: 3em 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Form h4,
.FAQSection h4,
.LeftSection h4 {
    background: var(--hap-gradient-color-reverse);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 10px 0;
}
.Form h1,
.LeftSection h1 {
    text-transform: uppercase;
    font-size: 65px;
    margin: 0;
}
.Form form {
    width: 90%;
    margin: 2em 0 3em 0;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}
.column {
    /* border: 1px solid #ccc; */
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    gap: 10px;
}

.upload {
    display: flex;
    gap: 20px;
}
#columnRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
}
.column .column {
    width: 40%;
}
.column p {
    margin: 0;
}
/* .column:nth-child(8) {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.column input,
.column textarea {
    padding: 15px;
    width: 95%;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.39);
    font-size: 15px;
}
.column select {
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    outline: none;
    border: 1px solid rgba(128, 128, 128, 0.39);
    font-size: 15px;
}
.span2 {
    grid-column: span 2;
}
.gridContainerSelect {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
.column label span {
    color: red;
}
.btn {
    flex: 1 1 auto;
    padding: 15px 25px;
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    background: linear-gradient(
            90deg,
            var(--c1, #f6d365),
            var(--c2, #fda085) 40%,
            var(--c1, #f6d365)
        )
        var(--x, 0) / 200%;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    border: none;
    width: fit-content;
}
.btn:hover {
    --x: 100%;
    cursor: pointer;
}
#btn1 {
    --c1: #c600ff;
    --c2: #007bff;
    color: white;
}
@media only screen and (max-width: 1200px) {
    .careerdiv h1 {
        font-size: 55px;
    }
    .careerdiv h4 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 1000px) {
    .careerdiv h1 {
        font-size: 45px;
    }
    .careerdiv h4 {
        font-size: 15px;
    }
}
@media only screen and (max-width: 850px) {
    .careerdiv h1 {
        font-size: 45px;
    }
    .careerdiv h4 {
        font-size: 15px;
    }
    .column {
        grid-column: span 2;
    }
}
@media only screen and (max-width: 600px) {
    .careerdiv h1 {
        font-size: 40px;
    }
    .careerdiv h4 {
        font-size: 13px;
    }
    .btn {
        font-size: 13px;
        padding: 15px 15px;
    }
}
@media only screen and (max-width: 450px) {
    .careerdiv h1 {
        font-size: 30px;
    }
    .careerdiv h4 {
        font-size: 13px;
    }
    .Form .column {
        font-size: 15px;
    }
    .column input,
    .column textarea {
        width: 90%;
    }
}
@media only screen and (max-width: 281px) {
    .careerdiv h1 {
        font-size: 25px;
    }
    .careerdiv h4 {
        font-size: 13px;
    }
    .column input,
    .column textarea {
        width: 80%;
    }
    .column select {
        width: 95%;
    }
}
