.topBanner {
  height: 90vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 0;
  position: relative;
  overflow: hidden;
  font-family: var(--primary-font-family);
}
.topBanner .backgroundimage {
  width: 100%;
  /* height: inherit; */
}
.topBanner .backgroundimage {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: inherit;
}
.topBanner h1 {
  margin: 0;
  text-align: center;
  margin-top: 2em;
  font-size: 80px;
}
.BannerHeading {
  color: whitesmoke;
  position: absolute;
  top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.BannerSubHeading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  padding: 7px 15px;
  border: 1px solid white;
  border-radius: 10px;
  margin-top: 1.5em;
}
.BannerSubHeading h3 {
  margin: 0;
  font-size: 18px;
}
@media only screen and (max-width: 1372px) {
  .topBanner {
    height: 80vh;
  }
  .topBanner .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: inherit;
  }
  .BannerHeading {
    top: 17vh;
  }
}
@media only screen and (max-width: 1200px) {
  .topBanner h1 {
    font-size: 70px;
  }
  .topBanner {
    height: 80vh;
  }
  .topBanner .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: inherit;
  }
  .BannerHeading {
    top: 17vh;
  }
}
@media only screen and (max-width: 1115px) {
  .topBanner {
    height: 70vh;
  }
  .topBanner .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: inherit;
  }
  .BannerHeading {
    top: 16vh;
  }
}
@media only screen and (max-width: 970px) {
  .topBanner {
    height: 65vh;
  }
  .topBanner .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: inherit;
  }
  .BannerHeading {
    top: 15vh;
  }
}
@media only screen and (max-width: 900px) {
  .topBanner {
    height: 60vh;
  }
  .topBanner .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: inherit;
  }
  .BannerHeading {
    top: 13vh;
  }
}
@media only screen and (max-width: 840px) {
  .topBanner {
    height: 58vh;
  }
  .topBanner .backgroundimage {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    height: inherit;
  }
  .BannerHeading {
    top: 13vh;
  }
  .topBanner h1 {
    font-size: 60px;
  }
  .BannerSubHeading h3 {
    font-size: 15px;
  }
}
@media only screen and (max-width: 805px) {
  .topBanner {
    height: 56vh;
  }

  .BannerHeading {
    top: 12vh;
  }
}
@media only screen and (max-width: 775px) {
  .topBanner {
    height: 52vh;
  }

  .BannerHeading {
    top: 11.5vh;
  }
}
@media only screen and (max-width: 715px) {
  .topBanner {
    height: 50vh;
  }

  .BannerHeading {
    top: 11vh;
  }
}
@media only screen and (max-width: 700px) {
  .BannerHeading {
    top: 10vh;
  }
}
@media only screen and (max-width: 600px) {
  .BannerHeading {
    top: 7vh;
  }
}
@media only screen and (max-width: 550px) {
  .topBanner h1 {
    font-size: 45px;
  }
  .BannerHeading {
    top: 10vh;
  }
  .BannerSubHeading h3 {
    font-size: 13px;
  }
  .BannerSubHeading {
    padding: 5px 10px;
  }
}
@media only screen and (max-width: 415px) {
  .topBanner {
    height: 48vh;
  }
  .BannerHeading {
    top: 10vh;
  }
  .topBanner h1 {
    font-size: 45px;
  }
  .BannerSubHeading h3 {
    font-size: 13px;
  }
  .BannerSubHeading {
    padding: 5px 10px;
    margin-top: 0.5em;
  }
}
@media only screen and (max-width: 380px) {
  .topBanner {
    height: 50vh;
  }
  .BannerHeading {
    top: 10vh;
    gap: 0;
  }
  .BannerSubHeading {
    margin-top: 0.25em;
  }
  .topBanner h1 {
    font-size: 45px;
  }
  .BannerSubHeading h3 {
    font-size: 13px;
  }
  .BannerSubHeading {
    padding: 5px 10px;
  }
}
