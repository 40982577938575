.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  border: 1px solid #ccc;
  z-index: 999;
  width: 90%;
  max-width: 600px;
}

.popup-content {
  text-align: center;
}

.popup-image {
  width: 100%;
  height: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 2em;
  cursor: pointer;
  color: black;
}

/* Additional styles for smaller screens */
@media only screen and (max-width: 600px) {
  .popup {
    width: 90%;
  }

  .close-button {
    font-size: 1.5em;
    top: 5px;
    right: 5px;
  }
}
